import './modules/menu/menu';
import './modules/calculator/calculator';
import './modules/show-more/show-more';
import {Form} from './modules/form-validate/form';
import {initModals} from './modules/modals/init-modals';
import {initAccordions} from './modules/accordion/init-accordion';
import {initAdvantagesSwiper, initServicesSwiper, initPortfolioSwiper} from './modules/slider/slider';
import './modules/social/social';
import './modules/code-country/code-country';
import initQuiz from './modules/quiz/quiz';

window.addEventListener('DOMContentLoaded', () => {

  window.addEventListener('load', () => {
    const form = new Form();
    window.form = form;
    form.init();
    initModals();
    initAccordions();
    initQuiz();
    initAdvantagesSwiper();
    initServicesSwiper();
    initPortfolioSwiper();
  });
});

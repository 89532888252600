document.addEventListener('DOMContentLoaded', function () {
  const phoneInputFields = document.querySelectorAll('input[type="tel"]'); // Выбираем все поля ввода с типом "tel"

  phoneInputFields.forEach(phoneInputField => {
    const iti = window.intlTelInput(phoneInputField, {
      initialCountry: "auto",
      geoIpLookup: function(callback) {
        fetch('https://ipinfo.io', {
          method: 'GET',
          mode: 'cors'
        }).then(response => response.json()).then(data => {
          const countryCode = data.country;
          callback(countryCode);
        }).catch(() => {
          callback('ru');
        });
      },
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js', // для форматирования и валидации
      separateDialCode: true,
    });


    phoneInputField.addEventListener('countrychange', () => {
      const dialCode = iti.getSelectedCountryData().dialCode;
      phoneInputField.value = `+${dialCode}`;
    });


    phoneInputField.addEventListener('input', () => {
      const formField = phoneInputField.closest('.subscribe__field');
      if (iti.isValidNumber()) {
        formField.classList.remove('is-invalid');
        formField.classList.add('is-valid');
      } else {
        formField.classList.remove('is-valid');
        formField.classList.add('is-invalid');
      }
    });
  });
});

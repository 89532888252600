function initAdvantagesSwiper() {
  let advantagesSwiper = new Swiper('.advantages-swiper', {

    pagination: {
      el: '.advantages__pagination',
    },

    direction: 'horizontal',
    loop: false,
    speed: 1000,
    autoplay: {
      delay: 8000,
    },

    on: {
      resize: function(advantagesSwiper) {
        if (window.innerWidth > 768) {
          advantagesSwiper.disable();
        }
        advantagesSwiper.enable();
      },
    },

  });

  return advantagesSwiper;
}

function initServicesSwiper() {
  let servicesSwiper = new Swiper('.services-swiper', {

    direction: 'horizontal',
    loop: false,
    initialSlide: 0,
    speed: 1000,

    pagination: {
      el: '.services__pagination',
    },

    on: {
      resize: function(servicesSwiper) {
        if (window.innerWidth > 768) {
          servicesSwiper.disable();
        }
        servicesSwiper.enable();
      },
    },

  });

  return servicesSwiper;
}

function initPortfolioSwiper() {
  let portfolioSwiper = new Swiper('.portfolio-swiper', {

    direction: 'horizontal',
    loop: false,
    initialSlide: 0,
    speed: 1000,

    pagination: {
      el: '.portfolio__pagination',
    },

    on: {
      resize: function(portfolioSwiper) {
        if (window.innerWidth > 768) {
          portfolioSwiper.disable();
        }
        portfolioSwiper.enable();
      },
    },
  });
  return portfolioSwiper;
}

// Этот костыль решает задачу рендеринга слайдера в моб. версии
// тк макетом слайдер предусмотрен только в мобильной версии
// в остальных версиях сетка ломается из-за таких классов как swiper-wrapper/swiper-slide
if (window.innerWidth > 768) {
  let sw = document.querySelectorAll('.swiper');
  sw.forEach((el) => el.classList.remove('swiper'));
  let swWrapper = document.querySelectorAll('.swiper-wrapper');
  swWrapper.forEach((el) => el.classList.remove('swiper-wrapper'));
  let swSlide = document.querySelectorAll('.swiper-slide');
  swSlide.forEach((e) => e.classList.remove('swiper-slide'));
}

export {initAdvantagesSwiper, initServicesSwiper, initPortfolioSwiper};

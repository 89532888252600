const radioGroups = document.querySelectorAll('.subscribe__group');

radioGroups.forEach((group) => {
  const radioAll = group.querySelectorAll('.radio__field');
  const fieldUserName = group.querySelector('.subscribe__field--username');

  radioAll.forEach((el) => {
    el.addEventListener('change', function () {
      if (el.id === 'telegram' || el.id === '-telegram' || el.id === '@telegram') {
        fieldUserName.style.display = 'block';
        fieldUserName.placeholder = `Введите ваш ник в ${el.id}`;
      } else {
        fieldUserName.style.display = 'none';
      }
    });
  });
});

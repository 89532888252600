const utilizationRates = {
  'up_to_3_years': {
    base: 100,
    rate: (price) => (price < 8500 ? 0.54 : 0.48),
  },
  '3_to_5_years': {
    base: 200,
    ratesByVolume: {
      1000: 1.5,
      1500: 1.7,
      1800: 2.5,
      2000: 2.7,
      2300: 2.7,
      3000: 3,
      3500: 3.6,
      3501: 3.6,
    },
  },
  '5_to_7_years': {
    base: 300,
    ratesByVolume: {
      1000: 3,
      1500: 3.2,
      1800: 3.5,
      2000: 4.8,
      2300: 4.8,
      3000: 5,
      3500: 5.7,
      3501: 5.7,
    },
  },
};

function calculateUtilization(age, volume, price) {
  const ageData = utilizationRates[age];

  if (!ageData) {
    throw new Error('Некорректный возраст автомобиля');
  }

  const util = ageData.base;

  let stavkaDVS;
  if (age === 'up_to_3_years') {
    stavkaDVS = ageData.rate(price);
  } else {
    stavkaDVS = ageData.ratesByVolume[volume];
  }

  return {util, stavkaDVS};
}

function calculator(e) {
  e.preventDefault();
  const target = document.getElementById('target').value;
  const age = document.getElementById('age').value;
  const price = parseInt(document.getElementById('price').value);
  const volume = parseInt(document.getElementById('volume').value);

  let baseTax = 200;
  let coef = 0.17;

  if (age === 'up_to_3_years') {
    volume == 3500 ? coef = 48.5 : volume == 5000 ? coef = 61.76 : coef = 0.17;
  } else if (age === '3_to_5_years' || age === '5_to_7_years') {
    volume == 3500 ? coef = 74.25 : volume == 5000 ? coef = 81.19 : coef = 0.26;
  }

  let sbor = baseTax * coef;
  // if (target === 'personal') {
  //   volume == 3500 ? coef = 48.5
  //     : volume >= 5000 : coef = 61.75;
  //   sbor = baseTax * coef;
  //   // sbor = age === 'up_to_3_years' ? 36 : 58;
  // } else {
  //   // sbor = age === 'up_to_3_years' ? 355 : 1326;
  // }


  const {util, stavkaDVS} = calculateUtilization(age, volume, price);
  const taxRast = age === 'up_to_3_years' ? Math.trunc(price * stavkaDVS) : Math.trunc(volume * stavkaDVS);

  const totalCost = sbor + taxRast + price;


  const item = document.querySelectorAll('.calc__item');
  item.forEach((el) => el.innerHTML = '');

  let priceAuto = document.querySelector('.calc__item--auto');
  priceAuto.innerHTML = `<p>Стоимость автомобиля: <span>${price.toLocaleString('ru-RU')} EUR </span></p>`;

  let tax = document.querySelector('.calc__item--tax');
  tax.innerHTML = `<p>Стоимость растаможки: <span>${taxRast.toLocaleString('ru-RU')} EUR</span></p>`;

  let utilSbor = document.querySelector('.calc__item--util');
  utilSbor.innerHTML = `<p>Стоимость утилизационного сбора: <span>${sbor.toLocaleString('ru-RU')} EUR</span></p>`;

  let result = document.querySelector('.calc__item--result');
  result.innerHTML = ` <strong>Стоимость автомобиля без учета доставки, но с учётом утилизационного сбора и растаможки: <span> ${totalCost.toLocaleString('ru-RU')} EUR </span> </strong>`;
  /*

  let utilSbor = document.createElement('span');
  utilSbor.textContent = `Стоимость утилизационного сбора: ${sbor.toFixed(2)} EUR`;
  document.querySelector('.calc__item--util').appendChild(utilSbor);

  let result = document.createElement('p');
  result.textContent = `Стоимость автомобиля без учета доставки, но с учётом утилизационного сбора и растаможки: ${totalCost.toFixed(2)} EUR`;
  document.querySelector('.calc__item--result').appendChild(result); */

}

const btnCalc = document.querySelector('.calc__submit');
btnCalc.addEventListener('click', calculator);
